import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { mapTaskItemToTaskItemInput } from '@social-garden/utils/taskItem.ts';
import {
  InstagramContentType,
  TiktokContentType,
  YoutubeContentType,
} from '@social-garden/utils/types.ts';
import { campaignDocument } from '@social-garden/api/documents/campaign.ts';
import { CreateCampaignState } from '../pages/CreateCampaign/CreateCampaign.tsx';

export default function useDuplicateCampaign() {
  const navigate = useNavigate();
  const [getCampaignDetails, { loading }] = useLazyQuery(campaignDocument);

  const duplicate = useCallback(
    async (id: string) => {
      const { data } = await getCampaignDetails({
        variables: {
          id,
        },
      });

      if (data === undefined) {
        return;
      }

      const state: CreateCampaignState = {
        values: {
          name: data.campaign.name,
          description: data.campaign.description,
          brandId: data.campaign.brand.id,
          includeContentFileOnSubmission:
            data.campaign.includeContentFileOnSubmission,
          taskItems: data.campaign.taskItems.map(mapTaskItemToTaskItemInput),
          address: data.campaign.address || undefined,
          questions: data.campaign.questions,
          gifts: data.campaign.gifts,
          categoryId: data.campaign.category.id,
          applyUntil: data.campaign.applyUntil
            ? new Date(data.campaign.applyUntil)
            : undefined,
          type: {
            channelType: data.campaign.typeable.channelType,
            instagramCampaign: {
              contentType:
                data.campaign.typeable.__typename === 'InstagramCampaign'
                  ? data.campaign.typeable.instagramContentType
                  : InstagramContentType.REEL,
            },
            youtubeCampaign: {
              contentType:
                data.campaign.typeable.__typename === 'YoutubeCampaign'
                  ? data.campaign.typeable.youtubeContentType
                  : YoutubeContentType.VIDEO,
            },
            tiktokCampaign: {
              contentType:
                data.campaign.typeable.__typename === 'TiktokCampaign'
                  ? data.campaign.typeable.tiktokContentType
                  : TiktokContentType.VIDEO,
            },
          },
          reward: {
            rewardType: data.campaign.rewardType,
            fixedMoneyRewardValue:
              data.campaign.fixedMoneyRewardValue || undefined,
          },
        },
      };

      navigate('/create-campaign', {
        state: state,
      });
    },
    [getCampaignDetails, navigate],
  );

  return [duplicate, { loading }] as const;
}
