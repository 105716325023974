import { useCallback } from 'react';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { CampaignStatus } from '@social-garden/api/gql/graphql.ts';

export default function useCampaignStatus() {
  const { t } = useTranslation(['common']);

  const getCampaignStatusName = useCallback(
    (status: CampaignStatus) => {
      switch (status) {
        case CampaignStatus.DRAFT:
          return t('common:campaignStatus.draft');
        case CampaignStatus.ACTIVE:
          return t('common:campaignStatus.active');
        case CampaignStatus.COMPLETED:
          return t('common:campaignStatus.completed');
        default:
          assertUnreachable(status);
      }
    },
    [t],
  );

  return { getCampaignStatusName };
}
