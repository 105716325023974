import { Grid2, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { useTranslation } from 'react-i18next';

export default function ChannelMissing() {
  const { t } = useTranslation(['manager']);

  return (
    <Grid2
      container
      spacing={1}
      display="flex"
      alignItems="center"
      color="text.secondary">
      <Grid2 size="auto">
        <FontAwesomeIcon icon={faBullhorn} />
      </Grid2>
      <Grid2 size="auto">
        <Typography fontSize={18}>{t('manager:channel.missing')}</Typography>
      </Grid2>
    </Grid2>
  );
}
