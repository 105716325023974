import { Alert, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  CampaignTypeableFragment,
  CreatorHasCampaignStatus,
} from '@social-garden/api/gql/graphql.ts';
import CreatorHasCampaignContentFile from './CreatorHasCampaignContentFile.tsx';
import ContentEmbed from '../../components/ContentEmbed.tsx';

export interface CreatorHasCampaignContentSubmittedProps {
  creatorHasCampaign: {
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
    campaign: {
      id: string;
      includeContentFileOnSubmission: boolean;
      typeable: CampaignTypeableFragment['typeable'];
    };
  };
}

export default function CreatorHasCampaignContentSubmitted({
  creatorHasCampaign,
}: CreatorHasCampaignContentSubmittedProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Stack spacing={2}>
      <Alert
        severity="info"
        action={
          <Button
            color="inherit"
            size="small"
            component={Link}
            to={`/campaign-report/${creatorHasCampaign.campaign.id}`}>
            {t(
              'manager:creatorHasCampaignFlow.contentSubmitted.openReportButton',
            )}
          </Button>
        }>
        {t('manager:creatorHasCampaignFlow.contentSubmitted.content')}
      </Alert>
      <CreatorHasCampaignContentFile creatorHasCampaign={creatorHasCampaign} />
      {creatorHasCampaign.contentUrl ? (
        <ContentEmbed
          src={creatorHasCampaign.contentUrl}
          channelType={creatorHasCampaign.campaign.typeable.channelType}
          maxWidth={800}
        />
      ) : null}
    </Stack>
  );
}
