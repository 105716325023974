import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { Button, Card, Link, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChannelType } from '@social-garden/utils/types.ts';
import { useMemo } from 'react';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import useOEmbed from '../hooks/useOEmbed.ts';

const DEFAULT_WIDTH = '100%';

interface ContentEmbedProps {
  src: string;
  channelType: ChannelType;
  maxWidth?: number;
  maxHeight?: number;
}

export default function ContentEmbed({
  src,
  channelType,
  maxWidth,
  maxHeight,
}: ContentEmbedProps) {
  const { t } = useTranslation(['manager']);
  const { data, loading } = useOEmbed(src, channelType, {
    maxWidth,
    maxHeight,
  });

  const defaultHeight = useMemo(() => {
    switch (channelType) {
      case ChannelType.INSTAGRAM:
        return 1120;
      case ChannelType.TIKTOK:
        return 1024;
      case ChannelType.YOUTUBE:
        return 600;
      default:
        assertUnreachable(channelType);
    }
  }, [channelType]);

  const iframeWidth = useMemo(() => {
    if (
      data === undefined ||
      data.width === undefined ||
      typeof data.width === 'string'
    ) {
      return DEFAULT_WIDTH;
    }
    return data.width;
  }, [data]);

  const iframeHeight = useMemo(() => {
    if (
      data === undefined ||
      data.height === undefined ||
      typeof data.height === 'string'
    ) {
      return defaultHeight;
    }
    return data.height;
  }, [data, defaultHeight]);

  if (loading) {
    return (
      <Skeleton
        variant="rounded"
        width={maxWidth ?? DEFAULT_WIDTH}
        height={maxHeight ?? defaultHeight}
      />
    );
  }

  if (data) {
    return (
      <Stack spacing={0.5}>
        <iframe
          style={{
            display: 'block',
            width: iframeWidth,
            height: iframeHeight,
            maxWidth,
            maxHeight,
            border: 0,
            margin: 0,
            padding: 0,
            outline: 0,
            boxShadow: 'none',
          }}
          srcDoc={data.html}
          title={data.title ?? src}
        />
        <Stack direction="row">
          <Link href={src} target="_blank" variant="caption">
            <FontAwesomeIcon
              style={{ marginRight: 4 }}
              icon={faArrowUpRightFromSquare}
            />
            {src}
          </Link>
        </Stack>
      </Stack>
    );
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        maxWidth: 800,
        aspectRatio: 1,
        p: 4,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        background: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
      variant="outlined">
      <Typography variant="h5" align="center">
        {t('manager:contentEmbed.unavailable.header')}
      </Typography>
      <Button
        variant="outlined"
        href={src}
        target="_blank"
        startIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}>
        {t('manager:contentEmbed.unavailable.openContentButton')}
      </Button>
    </Card>
  );
}
