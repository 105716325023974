import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  acceptPreviewDocument,
  retryPreviewDocument,
} from '@social-garden/api/documents/creatorHasCampaign.ts';
import {
  RetryPreviewFieldValues,
  RetryPreviewSchema,
} from '../../constants/ValidationSchema.ts';
import { RenderControllerElement } from '../../utils/types.ts';

export interface ReviewPreviewSubmissionProps {
  creatorHasCampaign: {
    id: string;
    creator: {
      username: string;
    };
  };
}

export default function ReviewPreviewSubmission({
  creatorHasCampaign,
}: ReviewPreviewSubmissionProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [isRetryDialogOpen, setIsRetryDialogOpen] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<RetryPreviewFieldValues>({
    mode: 'all',
    resolver: zodResolver(RetryPreviewSchema),
    defaultValues: {
      reason: '',
    },
  });

  const [acceptPreview, { loading: acceptPreviewLoading }] = useMutation(
    acceptPreviewDocument,
  );

  const [retryPreview, { loading: retryPreviewLoading }] =
    useMutation(retryPreviewDocument);

  const handleOnAcceptPreview = useCallback(async () => {
    await acceptPreview({
      variables: {
        creatorHasCampaignId: creatorHasCampaign.id,
      },
    });
  }, [acceptPreview, creatorHasCampaign.id]);

  const handleOnValid = useCallback(
    async ({ reason }: RetryPreviewFieldValues) => {
      await retryPreview({
        variables: {
          input: {
            creatorHasCampaignId: creatorHasCampaign.id,
            reason,
          },
        },
      });
    },
    [creatorHasCampaign.id, retryPreview],
  );

  const openRetryDialog = useCallback(() => setIsRetryDialogOpen(true), []);

  const closeRetryDialog = useCallback(() => setIsRetryDialogOpen(false), []);

  const renderReason = useCallback<
    RenderControllerElement<RetryPreviewFieldValues, 'reason'>
  >(
    ({
      field: { name, value, onBlur, onChange },
      fieldState: { error, invalid },
    }) => (
      <TextField
        required
        fullWidth
        variant="outlined"
        multiline
        minRows={3}
        label={t('common:previewRevision.retriedReason')}
        name={name}
        defaultValue={value}
        error={invalid}
        helperText={error?.message}
        onBlur={onBlur}
        onChange={onChange}
      />
    ),
    [t],
  );

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="warning" onClick={openRetryDialog}>
          {t('common:retry')}
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={acceptPreviewLoading}
          onClick={handleOnAcceptPreview}>
          {t('common:accept')}
        </Button>
      </Stack>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isRetryDialogOpen}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(handleOnValid),
        }}
        onClose={closeRetryDialog}>
        <DialogTitle>
          {t('manager:reviewPreviewSubmission.retryDialog.title', {
            username: creatorHasCampaign.creator.username,
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {t('manager:reviewPreviewSubmission.retryDialog.description')}
            </DialogContentText>
            <Controller<RetryPreviewFieldValues, 'reason'>
              render={renderReason}
              name="reason"
              control={control}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="reset" color="inherit" onClick={closeRetryDialog}>
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            color="warning"
            disabled={!isValid || retryPreviewLoading}>
            {t('common:retry')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
