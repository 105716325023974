import { useTranslation } from 'react-i18next';
import {
  Alert,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import ReviewCampaignApplication, {
  ReviewCampaignApplicationProps,
} from './ReviewCampaignApplication.tsx';
import ReviewPreviewSubmission, {
  ReviewPreviewSubmissionProps,
} from './ReviewPreviewSubmission.tsx';
import CampaignApplication, {
  CampaignApplicationProps,
} from './CampaignApplication.tsx';
import CreatorHasCampaignContentSubmitted, {
  CreatorHasCampaignContentSubmittedProps,
} from './CreatorHasCampaignContentSubmitted.tsx';
import Section from '../../components/Section.tsx';
import CreatorHasCampaignPreview, {
  CreatorHasCampaignPreviewProps,
} from './CreatorHasCampaignPreview.tsx';

const STEPS = [0, 1, 2, 3, 4] as const;
type StepIndex = (typeof STEPS)[number];

function getCurrentStepIndexFromStatus(
  status: CreatorHasCampaignStatus,
): StepIndex {
  switch (status) {
    case CreatorHasCampaignStatus.APPLIED:
      return 0;
    case CreatorHasCampaignStatus.PREVIEW_PENDING:
    case CreatorHasCampaignStatus.APPLICATION_DECLINED:
    case CreatorHasCampaignStatus.CAMPAIGN_SUSPENDED:
    case CreatorHasCampaignStatus.DISCONTINUED:
      return 1;
    case CreatorHasCampaignStatus.PREVIEW_SUBMITTED:
      return 2;
    case CreatorHasCampaignStatus.CONTENT_PENDING:
      return 3;
    case CreatorHasCampaignStatus.CONTENT_SUBMITTED:
    case CreatorHasCampaignStatus.REPORT_FAILED:
    case CreatorHasCampaignStatus.REPORT_COMPLETED:
      return 4;
    default:
      assertUnreachable(status);
  }
}

function isStepCompleted(
  stepIndex: StepIndex,
  status: CreatorHasCampaignStatus,
) {
  return (
    getCurrentStepIndexFromStatus(status) > stepIndex ||
    getCurrentStepIndexFromStatus(status) === STEPS[STEPS.length - 1]
  );
}

function isStepActive(stepIndex: StepIndex, status: CreatorHasCampaignStatus) {
  return getCurrentStepIndexFromStatus(status) >= stepIndex;
}

function isStepDisabled(
  stepIndex: StepIndex,
  status: CreatorHasCampaignStatus,
) {
  return getCurrentStepIndexFromStatus(status) < stepIndex;
}

type CreatorHasCampaignFlowProps = CampaignApplicationProps &
  ReviewCampaignApplicationProps &
  CreatorHasCampaignPreviewProps &
  ReviewPreviewSubmissionProps &
  CreatorHasCampaignContentSubmittedProps & {
    creatorHasCampaign: {
      status: CreatorHasCampaignStatus;
      discontinuedReason?: string | null;
    };
  };

export default function CreatorHasCampaignFlow({
  creatorHasCampaign,
}: CreatorHasCampaignFlowProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Stepper
      sx={{
        maxWidth: 800,
      }}
      activeStep={getCurrentStepIndexFromStatus(creatorHasCampaign.status)}
      orientation="vertical"
      nonLinear>
      <Step
        active={isStepActive(0, creatorHasCampaign.status)}
        completed={isStepCompleted(0, creatorHasCampaign.status)}
        disabled={isStepDisabled(0, creatorHasCampaign.status)}>
        <StepLabel>
          <Typography variant="h6">
            {t('manager:creatorHasCampaignFlow.reviewApplicationStep.label')}
          </Typography>
        </StepLabel>
        <StepContent>
          {isStepCompleted(0, creatorHasCampaign.status) ? (
            <CampaignApplication creatorHasCampaign={creatorHasCampaign} />
          ) : (
            <ReviewCampaignApplication
              creatorHasCampaign={creatorHasCampaign}
            />
          )}
        </StepContent>
      </Step>
      <Step
        active={isStepActive(1, creatorHasCampaign.status)}
        completed={isStepCompleted(1, creatorHasCampaign.status)}
        disabled={isStepDisabled(1, creatorHasCampaign.status)}>
        {creatorHasCampaign.status ===
        CreatorHasCampaignStatus.APPLICATION_DECLINED ? (
          <>
            <StepLabel error>
              <Typography variant="h6">
                {t('common:creatorHasCampaignStatus.applicationDeclined')}
              </Typography>
            </StepLabel>
            <StepContent>
              <Alert severity="info">
                {t(
                  'manager:creatorHasCampaignFlow.applicationDeclined.content',
                )}
              </Alert>
            </StepContent>
          </>
        ) : null}
        {creatorHasCampaign.status ===
        CreatorHasCampaignStatus.CAMPAIGN_SUSPENDED ? (
          <>
            <StepLabel error>
              <Typography variant="h6">
                {t('common:creatorHasCampaignStatus.campaignSuspended')}
              </Typography>
            </StepLabel>
            <StepContent>
              <Alert severity="info">
                {t('manager:creatorHasCampaignFlow.campaignSuspended.content')}
              </Alert>
            </StepContent>
          </>
        ) : null}
        {creatorHasCampaign.status === CreatorHasCampaignStatus.DISCONTINUED ? (
          <>
            <StepLabel error>
              <Typography variant="h6">
                {t('common:creatorHasCampaignStatus.discontinued')}
              </Typography>
            </StepLabel>
            <StepContent>
              <Stack spacing={2}>
                <Alert severity="info">
                  {t('manager:creatorHasCampaignFlow.discontinued.content')}
                </Alert>
                <Section
                  header={t('common:creatorHasCampaign.discontinuedReason')}>
                  <Typography>
                    {creatorHasCampaign.discontinuedReason}
                  </Typography>
                </Section>
              </Stack>
            </StepContent>
          </>
        ) : null}
        {creatorHasCampaign.status ===
        CreatorHasCampaignStatus.PREVIEW_PENDING ? (
          <>
            <StepLabel>
              <Typography variant="h6">
                {t('common:creatorHasCampaignStatus.previewPending')}
              </Typography>
            </StepLabel>
            <StepContent>
              <Alert severity="info">
                {t('manager:creatorHasCampaignFlow.previewPending.content')}
              </Alert>
            </StepContent>
          </>
        ) : null}
      </Step>
      <Step
        active={isStepActive(2, creatorHasCampaign.status)}
        completed={isStepCompleted(2, creatorHasCampaign.status)}
        disabled={isStepDisabled(2, creatorHasCampaign.status)}>
        <StepLabel>
          <Typography variant="h6">
            {t('common:creatorHasCampaignStatus.previewSubmitted')}
          </Typography>
        </StepLabel>
        <StepContent>
          {isStepCompleted(2, creatorHasCampaign.status) ? (
            <CreatorHasCampaignPreview
              creatorHasCampaign={creatorHasCampaign}
            />
          ) : (
            <Stack spacing={2}>
              <Alert severity="info">
                {t('manager:creatorHasCampaign.previewSubmitted.content')}
              </Alert>
              <CreatorHasCampaignPreview
                creatorHasCampaign={creatorHasCampaign}
              />
              <ReviewPreviewSubmission
                creatorHasCampaign={creatorHasCampaign}
              />
            </Stack>
          )}
        </StepContent>
      </Step>
      {creatorHasCampaign.status ===
      CreatorHasCampaignStatus.CONTENT_PENDING ? (
        <Step
          active={isStepActive(3, creatorHasCampaign.status)}
          completed={isStepCompleted(3, creatorHasCampaign.status)}
          disabled={isStepDisabled(3, creatorHasCampaign.status)}>
          <StepLabel>
            <Typography variant="h6">
              {t('common:creatorHasCampaignStatus.contentPending')}
            </Typography>
          </StepLabel>
          <StepContent>
            <Alert severity="info">
              {t('manager:creatorHasCampaignFlow.contentPending.content')}
            </Alert>
          </StepContent>
        </Step>
      ) : null}
      <Step
        active={isStepActive(4, creatorHasCampaign.status)}
        completed={isStepCompleted(4, creatorHasCampaign.status)}
        disabled={isStepDisabled(4, creatorHasCampaign.status)}>
        <StepLabel>
          <Typography variant="h6">
            {t('common:creatorHasCampaignStatus.contentSubmitted')}
          </Typography>
        </StepLabel>
        <StepContent>
          <CreatorHasCampaignContentSubmitted
            creatorHasCampaign={creatorHasCampaign}
          />
        </StepContent>
      </Step>
    </Stepper>
  );
}
