import { useTranslation } from 'react-i18next';
import { Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  formatEuroCents,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import ReportStatisticCard from '../../components/ReportStatisticCard.tsx';
import CampaignReportToolbar from './CampaignReportToolbar.tsx';
import CampaignReportDataStatus from './CampaignReportDataStatus.tsx';

interface InstagramReelCampaignReportData {
  id: string;
  completedCount: number;
  followerCount: number;
  plays: number;
  likes: number;
  comments: number;
  shares: number;
  reach: number;
  engagementRate?: number | null;
  cpv?: number | null;
  price: number;
  creatorHasCampaignReports: {
    id: string;
    creatorHasCampaign: {
      id: string;
      status: CreatorHasCampaignStatus;
      creator: {
        username: string;
      };
    };
    followerCount: number;
    plays: number;
    likes: number;
    comments: number;
    shares: number;
    reach: number;
    engagementRate?: number | null;
    cpv?: number | null;
    price: number;
  }[];
}

interface InstagramReelCampaignReportProps {
  data: InstagramReelCampaignReportData;
}

export default function InstagramReelCampaignReport({
  data,
}: InstagramReelCampaignReportProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={4}>
      <Stack spacing={2} direction="row" flexWrap="wrap">
        <ReportStatisticCard
          label={t('common:report.completed')}
          value={data.completedCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.followers')}
          value={data.followerCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.plays')}
          description={t('common:report.playsDescription')}
          value={data.plays.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.likes')}
          value={data.likes.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.comments')}
          value={data.comments.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.shares')}
          value={data.shares.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.reach')}
          description={t('common:report.reachDescription')}
          value={data.reach.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.engagementRate')}
          description={t('common:report.engagementRateDescription')}
          value={
            data.engagementRate
              ? formatPercentage(data.engagementRate, resolvedLanguage)
              : 'n/a'
          }
        />
        <ReportStatisticCard
          label={t('common:report.cpv')}
          description={t('common:report.cpvDescription')}
          value={data.cpv ? formatEuroCents(data.cpv, resolvedLanguage) : 'n/a'}
        />
        <ReportStatisticCard
          label={t('common:report.price')}
          value={formatEuroCents(data.price, resolvedLanguage)}
        />
      </Stack>
      <DataGrid
        rows={data.creatorHasCampaignReports}
        columns={[
          {
            flex: 1,
            minWidth: 150,
            field: 'creatorUsername',
            headerName: t('common:username'),
            valueGetter: (_, row) => row.creatorHasCampaign.creator.username,
            renderCell: ({ row }) => (
              <Link
                component={RouterLink}
                to={`/creator-has-campaign/${row.creatorHasCampaign.id}`}>
                {row.creatorHasCampaign.creator.username}
              </Link>
            ),
          },
          {
            width: 120,
            field: 'followerCount',
            headerName: t('common:report.followers'),
            valueGetter: (_, row) =>
              row.followerCount.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'plays',
            headerName: t('common:report.plays'),
            description: t('common:report.playsDescription'),
            valueGetter: (_, row) => row.plays.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'likes',
            headerName: t('common:report.likes'),
            valueGetter: (_, row) => row.likes.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'comments',
            headerName: t('common:report.comments'),
            valueGetter: (_, row) =>
              row.comments.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'shares',
            headerName: t('common:report.shares'),
            valueGetter: (_, row) =>
              row.shares.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'reach',
            headerName: t('common:report.reach'),
            description: t('common:report.reachDescription'),
            valueGetter: (_, row) => row.reach.toLocaleString(resolvedLanguage),
          },
          {
            width: 160,
            field: 'engagementRate',
            headerName: t('common:report.engagementRate'),
            description: t('common:report.engagementRateDescription'),
            valueGetter: (_, row) =>
              row.engagementRate
                ? formatPercentage(row.engagementRate, resolvedLanguage)
                : 'n/a',
          },
          {
            width: 100,
            field: 'cpv',
            headerName: t('common:report.cpv'),
            description: t('common:report.cpvDescription'),
            valueGetter: (_, row) =>
              row.cpv ? formatEuroCents(row.cpv, resolvedLanguage) : 'n/a',
          },
          {
            width: 120,
            field: 'price',
            headerName: t('common:report.price'),
            valueGetter: (_, row) =>
              formatEuroCents(row.price, resolvedLanguage),
          },
          {
            width: 50,
            field: 'status',
            align: 'center',
            disableExport: true,
            renderHeader: () => null,
            valueGetter: (_, row) => row.creatorHasCampaign.status,
            renderCell: ({ row }) => (
              <CampaignReportDataStatus
                creatorHasCampaignStatus={row.creatorHasCampaign.status}
              />
            ),
          },
        ]}
        disableColumnSorting={false}
        slots={{
          toolbar: CampaignReportToolbar,
          pagination: null,
        }}
      />
    </Stack>
  );
}
