import { Chip, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CampaignStatisticsFragment } from '@social-garden/api/gql/graphql.ts';

interface CampaignStatisticsProps {
  statistics: CampaignStatisticsFragment;
}

export default function CampaignStatistics({
  statistics,
}: CampaignStatisticsProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Grid2 container spacing={1}>
      <Grid2 container size={12}>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.applications', {
              count: statistics.applications,
            })}
            color="applied"
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.applicationsAccepted', {
              count: statistics.applicationsAccepted,
            })}
            color="previewPending"
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.previewsAccepted', {
              count: statistics.previewsAccepted,
            })}
            color="contentPending"
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.contentSubmissions', {
              count: statistics.contentSubmissions,
            })}
            color="contentSubmitted"
            variant="filled"
            size="small"
          />
        </Grid2>
      </Grid2>
      {statistics.applicationsToReview > 0 ||
      statistics.previewsToReview > 0 ? (
        <Grid2 container size={12}>
          {statistics.applicationsToReview > 0 ? (
            <Grid2 size="auto">
              <Chip
                label={t('campaign.statistics.applicationsToReview', {
                  count: statistics.applicationsToReview,
                })}
                color="applied"
                variant="filled"
                size="small"
              />
            </Grid2>
          ) : null}
          {statistics.previewsToReview > 0 ? (
            <Grid2 size="auto">
              <Chip
                label={t('campaign.statistics.previewsToReview', {
                  count: statistics.previewsToReview,
                })}
                color="previewSubmitted"
                variant="filled"
                size="small"
              />
            </Grid2>
          ) : null}
        </Grid2>
      ) : null}
    </Grid2>
  );
}
