import { useQuery } from '@apollo/client';
import { previewFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { Stack, StackProps, Typography } from '@mui/material';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useCallback, useState } from 'react';
import ReplaceUrlWithLink from './ReplaceUrlWithLink.tsx';
import SuspenseFallback from './SuspenseFallback.tsx';

function PreviewRevisionCaption({ children }: { children: string }) {
  return (
    <Typography
      style={{
        whiteSpace: 'pre-line',
      }}
      variant="caption">
      <ReplaceUrlWithLink>{children}</ReplaceUrlWithLink>
    </Typography>
  );
}

interface PreviewRevisionProps extends StackProps {
  mediaStyle?: CSSProperties;
  id: string;
  caption: string;
  mediaType: 'image' | 'video';
}

export default function PreviewRevision({
  mediaStyle,
  id,
  caption,
  mediaType,
  ...rest
}: PreviewRevisionProps) {
  const { t } = useTranslation(['common']);

  const [autoPlayVideo, setAutoPlayVideo] = useState(false);

  const { data, loading, error, refetch } = useQuery(previewFileDocument, {
    variables: {
      id,
    },
  });

  const handleOnVideoError = useCallback(async () => {
    setAutoPlayVideo(true);
    await refetch();
  }, [refetch]);

  if (loading && data === undefined) {
    return <SuspenseFallback />;
  }

  if (error || data === undefined) {
    return null;
  }

  switch (mediaType) {
    case 'image':
      return (
        <Stack spacing={0.5} {...rest}>
          <img style={mediaStyle} src={data.previewFile.uri} alt="Preview" />
          <PreviewRevisionCaption>{caption}</PreviewRevisionCaption>
        </Stack>
      );
    case 'video':
      return (
        <Stack spacing={0.5} {...rest}>
          <video
            key={data.previewFile.uri}
            style={mediaStyle}
            controls
            autoPlay={autoPlayVideo}
            controlsList="nodownload"
            preload="none">
            <source src={data.previewFile.uri} onError={handleOnVideoError} />
            <track kind="captions" />
            {t('common:videoplayer.notSupported')}
          </video>
          <PreviewRevisionCaption>{caption}</PreviewRevisionCaption>
        </Stack>
      );
    default:
      assertUnreachable(mediaType);
  }
}
