import { Button, Stack, StackProps, Typography } from '@mui/material';
import {
  assertUnreachable,
  formatEuroCents,
  formatBytes,
} from '@social-garden/utils/helpers.ts';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-thin-svg-icons/faSeedling';
import { faTreeDeciduous } from '@fortawesome/pro-thin-svg-icons/faTreeDeciduous';
import { faTrees } from '@fortawesome/pro-thin-svg-icons/faTrees';
import { ChannelType } from '@social-garden/utils/types.ts';
import { getChannelTypeName } from '@social-garden/utils/channel.ts';
import {
  RecurringIntervalPrice,
  SubscriptionPriceLookupKey,
} from '@social-garden/api/gql/graphql.ts';

enum SubscriptionTier {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface Price {
  id: string;
  amount: number;
  discount?: number | null;
  recurringInterval: RecurringIntervalPrice;
  lookupKey: SubscriptionPriceLookupKey;
}

export interface SubscriptionPlan {
  id: string;
  name: string;
  price?: Price | null;
  bookingFeePercentage: number;
  minBookingFee: number;
  maxBrandCount?: number | null;
  maxCampaignCount?: number | null;
  storageSpaceLimit: number;
}

interface SubscriptionPlanItemProps<T> extends StackProps {
  subscriptionPlan: T;
  disabled?: boolean;
  disablePriceHighlighting?: boolean;
  onPlanClick?(subscriptionPlan: T): void;
}

export default function SubscriptionPlanItem<T extends SubscriptionPlan>({
  sx,
  borderRadius = 2,
  subscriptionPlan,
  disabled,
  disablePriceHighlighting,
  onPlanClick,
  ...rest
}: SubscriptionPlanItemProps<T>) {
  const {
    t,
    i18n: { resolvedLanguage, languages },
  } = useTranslation(['manager']);

  const subscriptionTier = useMemo(() => {
    const lookupKey = subscriptionPlan.price?.lookupKey;
    switch (lookupKey) {
      case SubscriptionPriceLookupKey.SMALL_MONTHLY:
      case SubscriptionPriceLookupKey.SMALL_YEARLY:
        return SubscriptionTier.SMALL;
      case SubscriptionPriceLookupKey.MEDIUM_MONTHLY:
      case SubscriptionPriceLookupKey.MEDIUM_YEARLY:
        return SubscriptionTier.MEDIUM;
      case SubscriptionPriceLookupKey.LARGE_MONTHLY:
      case SubscriptionPriceLookupKey.LARGE_YEARLY:
        return SubscriptionTier.LARGE;
      case undefined:
        return undefined;
      default:
        assertUnreachable(lookupKey);
    }
  }, [subscriptionPlan.price?.lookupKey]);

  const priceHighlighted = useMemo(
    () =>
      !disablePriceHighlighting && subscriptionTier === SubscriptionTier.MEDIUM,
    [disablePriceHighlighting, subscriptionTier],
  );

  const priceIcon = useMemo(() => {
    switch (subscriptionTier) {
      case SubscriptionTier.SMALL:
        return faSeedling;
      case SubscriptionTier.MEDIUM:
        return faTreeDeciduous;
      case SubscriptionTier.LARGE:
        return faTrees;
      case undefined:
        return undefined;
      default:
        assertUnreachable(subscriptionTier);
    }
  }, [subscriptionTier]);

  const priceDiscountEuro = useMemo(
    () =>
      subscriptionPlan.price
        ? formatEuroCents(
            subscriptionPlan.price.discount || 0,
            resolvedLanguage ?? languages[0],
          )
        : undefined,
    [subscriptionPlan.price, resolvedLanguage, languages],
  );

  const priceIntervalText = useMemo(() => {
    const recurringInterval = subscriptionPlan.price?.recurringInterval;
    switch (recurringInterval) {
      case RecurringIntervalPrice.MONTH:
        return t('subscriptionPlan.priceIntervalMonthly');
      case RecurringIntervalPrice.YEAR:
        return t('subscriptionPlan.priceIntervalYearly');
      case undefined:
        return undefined;
      default:
        assertUnreachable(recurringInterval);
    }
  }, [subscriptionPlan.price, t]);

  const minBookingFeeEuro = useMemo(
    () =>
      formatEuroCents(
        subscriptionPlan.minBookingFee,
        resolvedLanguage ?? languages[0],
      ),
    [subscriptionPlan.minBookingFee, resolvedLanguage, languages],
  );

  const handleOnClick = useCallback(() => {
    if (onPlanClick) {
      onPlanClick(subscriptionPlan);
    }
  }, [subscriptionPlan, onPlanClick]);

  return (
    <Stack
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        borderColor: (theme) =>
          priceHighlighted ? theme.palette.primary.main : 'transparent',
        borderWidth: 2,
        borderStyle: 'solid',
        ...sx,
      }}
      borderRadius={borderRadius}
      {...rest}>
      {!disablePriceHighlighting ? (
        <Stack
          sx={{
            visibility: priceHighlighted ? 'visible' : 'hidden',
            backgroundColor: (theme) => theme.palette.primary.main,
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
          px={3}
          alignItems="center"
          justifyContent="center">
          <Typography
            sx={{
              userSelect: 'none',
              color: (theme) => theme.palette.primary.contrastText,
            }}
            align="center"
            fontWeight={500}>
            {t('subscriptionPlan.highlighted')}
          </Typography>
        </Stack>
      ) : null}
      <Stack p={4} spacing={4}>
        <Typography variant="h4" align="center" textTransform="capitalize">
          {subscriptionPlan.name}
        </Typography>
        {priceIcon ? (
          <Stack
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary.dark
                  : theme.palette.primary.main,
            }}
            alignItems="center"
            justifyContent="center">
            <FontAwesomeIcon icon={priceIcon} size="9x" />
          </Stack>
        ) : null}
        {subscriptionPlan.price ? (
          <Stack>
            {subscriptionPlan.price.amount ? (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center">
                <Typography variant="h3" align="right">
                  {formatEuroCents(
                    subscriptionPlan.price.amount,
                    resolvedLanguage ?? languages[0],
                  )}
                </Typography>
                <Typography
                  flex={0}
                  variant="body2"
                  lineHeight={1.25}
                  color="text.secondary"
                  align="left">
                  {priceIntervalText}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="h3" align="center">
                {t('subscriptionPlan.priceFree')}
              </Typography>
            )}
            {subscriptionPlan.price.discount ? (
              <Typography variant="subtitle1" align="center">
                {t('subscriptionPlan.priceDiscount', {
                  discount: priceDiscountEuro,
                })}
              </Typography>
            ) : null}
          </Stack>
        ) : null}
        {onPlanClick ? (
          <Button
            fullWidth
            variant="contained"
            size="large"
            disabled={disabled}
            onClick={handleOnClick}>
            {t('subscriptionPlan.subscribeButton')}
          </Button>
        ) : null}
        <Stack spacing={2}>
          <Typography variant="subtitle1" fontWeight={600}>
            {t('subscriptionPlan.feature.header')}
          </Typography>
          <ul style={{ margin: 0, paddingLeft: 25 }}>
            <li>
              {subscriptionPlan.maxCampaignCount ? (
                <Trans
                  i18nKey="subscriptionPlan.feature.maxCampaignCount"
                  ns="manager"
                  count={subscriptionPlan.maxCampaignCount}
                  components={[
                    <Typography key="count" fontWeight={500} component="span">
                      {subscriptionPlan.maxCampaignCount}
                    </Typography>,
                  ]}
                />
              ) : (
                <Trans
                  i18nKey="subscriptionPlan.feature.unlimitedCampaignCount"
                  ns="manager"
                  components={[
                    <Typography
                      key="unlimited"
                      fontWeight={500}
                      component="span"
                    />,
                  ]}
                />
              )}
            </li>
            <li>
              {subscriptionPlan.maxBrandCount ? (
                <Trans
                  i18nKey="subscriptionPlan.feature.maxBrandCount"
                  ns="manager"
                  count={subscriptionPlan.maxBrandCount}
                  components={[
                    <Typography key="count" fontWeight={500} component="span">
                      {subscriptionPlan.maxBrandCount}
                    </Typography>,
                  ]}
                />
              ) : (
                <Trans
                  i18nKey="subscriptionPlan.feature.unlimitedBrandCount"
                  ns="manager"
                  components={[
                    <Typography
                      key="unlimited"
                      fontWeight={500}
                      component="span"
                    />,
                  ]}
                />
              )}
            </li>
            <li>
              <Trans
                i18nKey="subscriptionPlan.feature.storageSpaceLimit"
                ns="manager"
                values={{
                  limit: formatBytes(
                    subscriptionPlan.storageSpaceLimit * 1024 * 1024,
                    resolvedLanguage,
                  ),
                }}
                components={[
                  <Typography key="limit" fontWeight={500} component="span">
                    {formatBytes(
                      subscriptionPlan.storageSpaceLimit * 1024 * 1024,
                      resolvedLanguage,
                    )}
                  </Typography>,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="subscriptionPlan.feature.bookingFeePercentage"
                ns="manager"
                values={{
                  feePercentage: subscriptionPlan.bookingFeePercentage,
                }}
                components={[
                  <Typography
                    key="feePercentage"
                    fontWeight={500}
                    component="span">
                    {subscriptionPlan.bookingFeePercentage}
                  </Typography>,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="subscriptionPlan.feature.minBookingFee"
                ns="manager"
                values={{ fee: minBookingFeeEuro }}
                components={[
                  <Typography key="fee" fontWeight={500} component="span">
                    {minBookingFeeEuro}
                  </Typography>,
                ]}
              />
            </li>
            <li>
              <Typography>
                {t('subscriptionPlan.feature.campaignReports')}
              </Typography>
            </li>
            {subscriptionTier === SubscriptionTier.MEDIUM ||
            subscriptionTier === SubscriptionTier.LARGE ? (
              <li>
                <Typography>
                  {t('subscriptionPlan.feature.emailAndPhoneSupport')}
                </Typography>
              </li>
            ) : (
              <li>
                <Typography>
                  {t('subscriptionPlan.feature.emailSupport')}
                </Typography>
              </li>
            )}
            {subscriptionTier === SubscriptionTier.LARGE ? (
              <li>
                <Typography fontWeight={500}>
                  {t('subscriptionPlan.feature.customerSuccessManager')}
                </Typography>
              </li>
            ) : null}
          </ul>
          <Typography variant="subtitle1" fontWeight={600}>
            {t('subscriptionPlan.channelReach.header')}
          </Typography>
          <ul style={{ margin: 0, paddingLeft: 25 }}>
            <li>
              <Typography>{getChannelTypeName(ChannelType.TIKTOK)}</Typography>
            </li>
            <li>
              <Typography>
                {getChannelTypeName(ChannelType.INSTAGRAM)}
              </Typography>
            </li>
            <li>
              <Typography>{getChannelTypeName(ChannelType.YOUTUBE)}</Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" fontWeight={600}>
            {t('subscriptionPlan.creatorPerks.header')}
          </Typography>
          <ul style={{ margin: 0, paddingLeft: 25 }}>
            <li>
              <Typography>
                {t('subscriptionPlan.creatorPerks.freeProducts')}
              </Typography>
            </li>
            <li>
              <Typography>
                {t('subscriptionPlan.creatorPerks.variableOrFixedRemuneration')}
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
    </Stack>
  );
}
