import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section.tsx';
import PriceSummary from '../../components/PriceSummary.tsx';
import DeliveryAddress from '../../components/DeliveryAddress.tsx';

export interface CampaignApplicationProps {
  creatorHasCampaign: {
    rewardValue?: number | null;
    price: number;
    answers: {
      id: string;
      answer: string;
      question: {
        question: string;
      };
    }[];
    campaignGiftCodes: {
      id: string;
      code: string;
      gift: {
        name: string;
      };
    }[];
    deliveryAddress?: {
      id: string;
      recipientName: string;
      streetAddressLine1: string;
      streetAddressLine2?: string | null;
      postcode: string;
      city: string;
      country: string;
    } | null;
  };
}

export default function CampaignApplication({
  creatorHasCampaign,
}: CampaignApplicationProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Stack spacing={4}>
      {creatorHasCampaign.answers.length > 0 ? (
        <Section
          header={t('manager:campaignApplication.questionsAndAnswers.header')}>
          {creatorHasCampaign.answers.map(({ id, question, answer }, index) => (
            <Stack key={id}>
              <Typography variant="subtitle2">
                {`${index + 1}. ${question.question}`}
              </Typography>
              <Typography>{answer}</Typography>
            </Stack>
          ))}
        </Section>
      ) : null}
      {creatorHasCampaign.campaignGiftCodes.length > 0 ? (
        <Section header={t('manager:campaignApplication.giftCode.header')}>
          {creatorHasCampaign.campaignGiftCodes.map(({ id, gift, code }) => (
            <Stack key={id}>
              <Typography variant="subtitle2">{gift.name}</Typography>
              <Typography>{code}</Typography>
            </Stack>
          ))}
        </Section>
      ) : null}
      {creatorHasCampaign.deliveryAddress ? (
        <Section
          header={t('manager:campaignApplication.deliveryAddress.header')}
          subHeader={t(
            'manager:campaignApplication.deliveryAddress.subHeader',
          )}>
          <DeliveryAddress
            deliveryAddress={creatorHasCampaign.deliveryAddress}
          />
        </Section>
      ) : null}
      <Section header={t('manager:campaignApplication.priceSummary.header')}>
        <PriceSummary
          price={creatorHasCampaign.price}
          rewardValue={creatorHasCampaign.rewardValue}
        />
      </Section>
    </Stack>
  );
}
