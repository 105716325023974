import {
  InstagramContentType,
  YoutubeContentType,
  TiktokContentType,
  CampaignTypeable,
} from './types';
import { assertUnreachable } from './helpers';

export function getContentTypeName(
  type: InstagramContentType | YoutubeContentType | TiktokContentType,
) {
  switch (type) {
    case InstagramContentType.REEL:
      return 'Reel';
    case InstagramContentType.POST:
      return 'Post';
    case InstagramContentType.STORY:
      return 'Story';
    case YoutubeContentType.VIDEO:
    case TiktokContentType.VIDEO:
      return 'Video';
    default:
      assertUnreachable(type);
  }
}

export function getContentTypeNameFromCampaignType(type: CampaignTypeable) {
  switch (type.__typename) {
    case 'InstagramCampaign':
      return getContentTypeName(type.instagramContentType);
    case 'YoutubeCampaign':
      return getContentTypeName(type.youtubeContentType);
    case 'TiktokCampaign':
      return getContentTypeName(type.tiktokContentType);
    default:
      assertUnreachable(type);
  }
}

export function getMediaTypeFromContentType(
  contentType: 'Reel' | 'Post' | 'Story' | 'Video',
) {
  switch (contentType) {
    case 'Post':
      return 'image';
    case 'Reel':
      return 'video';
    case 'Story':
      return 'video';
    case 'Video':
      return 'video';
    default:
      assertUnreachable(contentType);
  }
}
