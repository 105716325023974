import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@apollo/client';
import { updateApplyUntilDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useCallback } from 'react';
import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { CampaignStatus } from '@social-garden/api/gql/graphql.ts';
import { getUTCStartOfDay } from '@social-garden/utils/helpers.ts';
import {
  UpdateApplyUntilFieldValues,
  UpdateApplyUntilSchema,
} from '../../constants/ValidationSchema.ts';
import ApplyUntilField from '../../components/ApplyUntilField.tsx';

interface UpdateApplyUntilDialogProps {
  open: boolean;
  campaign: {
    id: string;
    status: CampaignStatus;
    applyUntil?: string | null;
  };
  onClose?: () => void;
}

export default function UpdateApplyUntilDialog({
  open,
  campaign,
  onClose,
}: UpdateApplyUntilDialogProps) {
  const { t } = useTranslation(['common', 'manager']);

  const [updateApplyUntil, { loading }] = useMutation(updateApplyUntilDocument);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<UpdateApplyUntilFieldValues>({
    resolver: zodResolver(UpdateApplyUntilSchema),
    mode: 'all',
    defaultValues: {
      applyUntil: campaign.applyUntil
        ? getUTCStartOfDay(campaign.applyUntil)
        : undefined,
    },
  });

  const handleOnUpdateApplyUntil = useCallback(
    async ({ applyUntil }: UpdateApplyUntilFieldValues) => {
      if (campaign.status !== CampaignStatus.ACTIVE) {
        return;
      }
      const response = await updateApplyUntil({
        variables: {
          input: {
            id: campaign.id,
            applyUntil: applyUntil
              ? formatISO(applyUntil, { representation: 'date' })
              : undefined,
          },
        },
      });
      if (response.data && onClose) {
        onClose();
      }
    },
    [campaign.id, campaign.status, updateApplyUntil, onClose],
  );

  if (campaign.status !== CampaignStatus.ACTIVE) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        noValidate: true,
        onSubmit: handleSubmit(handleOnUpdateApplyUntil),
      }}>
      <DialogTitle>{t('manager:campaign.updateApplyUntil.title')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <DialogContentText>
            {t('manager:campaign.updateApplyUntil.description')}
          </DialogContentText>
          <Controller<UpdateApplyUntilFieldValues, 'applyUntil'>
            name="applyUntil"
            control={control}
            render={ApplyUntilField}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!isValid || loading}>
          {t('common:update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
