import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { CampaignRewardType } from '@social-garden/api/gql/graphql.ts';

export default function useCampaignRewardType() {
  const { t } = useTranslation(['common']);

  const getCampaignRewardTypeName = useCallback(
    (type: CampaignRewardType) => {
      switch (type) {
        case CampaignRewardType.NONE:
          return t('common:campaignRewardType.none');
        case CampaignRewardType.FIXED_MONEY_REWARD:
          return t('common:campaignRewardType.fixedMoneyReward');
        case CampaignRewardType.VARIABLE_MONEY_REWARD:
          return t('common:campaignRewardType.variableMoneyReward');
        case CampaignRewardType.DYNAMIC_MONEY_REWARD:
          return t('common:campaignRewardType.dynamicMoneyReward');
        default:
          assertUnreachable(type);
      }
    },
    [t],
  );

  return { getCampaignRewardTypeName };
}
