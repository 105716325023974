import { MenuItem, Select, SelectProps } from '@mui/material';
import { InstagramContentType } from '@social-garden/api/gql/graphql.ts';
import { getContentTypeName } from '@social-garden/utils/content.ts';

export default function InstagramContentTypeSelect(
  props: Omit<SelectProps<InstagramContentType>, 'children'>,
) {
  return (
    <Select<InstagramContentType> {...props}>
      {Object.values(InstagramContentType).map((value) => (
        <MenuItem key={value} value={value}>
          {getContentTypeName(value)}
        </MenuItem>
      ))}
    </Select>
  );
}
