import { useSuspenseQuery } from '@apollo/client';
import { Chip, Container, Grid2, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { creatorHasCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useCallback, useEffect } from 'react';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import CreatorHasCampaignHeader from './CreatorHasCampaignHeader.tsx';
import CreatorHasCampaignFlow from './CreatorHasCampaignFlow.tsx';
import ReplaceUrlWithLink from '../../components/ReplaceUrlWithLink.tsx';
import useCreatorHasCampaignStatus from '../../hooks/useCreatorHasCampaignStatus.ts';

export default function CreatorHasCampaign() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);

  const {
    data: { creatorHasCampaign },
  } = useSuspenseQuery(creatorHasCampaignDocument, {
    variables: {
      id: id ?? '',
    },
  });

  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  const handleOnNavigateToCampaign = useCallback(() => {
    navigate(`/campaign-details/${creatorHasCampaign.campaign.id}`);
  }, [navigate, creatorHasCampaign.campaign.id]);

  useEffect(() => {
    if (id && searchParams.has('tab-index', '1')) {
      navigate(`/messages/${id}`);
    }
  }, [navigate, id, searchParams]);

  return (
    <Container>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <CreatorHasCampaignHeader creatorHasCampaign={creatorHasCampaign} />
        </Grid2>
        {creatorHasCampaign.creator.description ? (
          <Grid2
            size={{
              xs: 12,
              md: 10,
              lg: 8,
              xl: 6,
            }}>
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}>
              <ReplaceUrlWithLink>
                {creatorHasCampaign.creator.description}
              </ReplaceUrlWithLink>
            </Typography>
          </Grid2>
        ) : null}
        <Grid2 container size={12} spacing={1}>
          <Grid2 size="auto">
            <Chip
              label={getCreatorHasCampaignStatusName(creatorHasCampaign.status)}
              variant="filled"
              color={getCreatorHasCampaignStatusColor(
                creatorHasCampaign.status,
              )}
            />
          </Grid2>
          <Grid2 size="auto">
            <Chip
              label={`${t('common:price')}: ${formatEuroCents(creatorHasCampaign.price, resolvedLanguage)}`}
              variant="filled"
            />
          </Grid2>
          <Grid2 size="auto">
            <Chip
              sx={{
                textDecoration: 'underline',
              }}
              label={`${t('manager:creatorHasCampaign.campaign.name.label')}: ${creatorHasCampaign.campaign.name}`}
              variant="filled"
              clickable
              onClick={handleOnNavigateToCampaign}
            />
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <CreatorHasCampaignFlow creatorHasCampaign={creatorHasCampaign} />
        </Grid2>
      </Grid2>
    </Container>
  );
}
