import { MenuItem, Select, Stack, Typography, Grid2 } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { subscriptionPlansDocument } from '@social-garden/api/documents/subscriptionPlan.ts';
import { checkoutSubscriptionForCompanyDocument } from '@social-garden/api/documents/company.ts';
import { parseAsStringEnum, useQueryState } from 'nuqs';
import { RecurringIntervalPrice } from '@social-garden/api/gql/graphql.ts';
import SubscriptionPlanItem, {
  Price,
  SubscriptionPlan,
} from './SubscriptionPlanItem.tsx';

interface SubscriptionPlanWithPrice extends SubscriptionPlan {
  price: Price;
}

export default function SubscriptionPlanTable() {
  const { t } = useTranslation(['manager']);
  const [recurringIntervalPrice, setRecurringIntervalPrice] = useQueryState(
    'recurring-interval',
    parseAsStringEnum<RecurringIntervalPrice>(
      Object.values(RecurringIntervalPrice),
    ).withDefault(RecurringIntervalPrice.MONTH),
  );
  const { data } = useSuspenseQuery(subscriptionPlansDocument);
  const [checkoutSubscriptionForCompany, { loading }] = useMutation(
    checkoutSubscriptionForCompanyDocument,
  );

  const filteredAndSortedSubscriptionPlans = useMemo<
    SubscriptionPlanWithPrice[]
  >(
    () =>
      data.subscriptionPlans
        .reduce<SubscriptionPlanWithPrice[]>((previousValue, currentValue) => {
          const intervalPrice = currentValue.prices.find(
            (p) => p.recurringInterval === recurringIntervalPrice,
          );
          if (intervalPrice === undefined) {
            return previousValue;
          }
          let discount: number | undefined = undefined;
          if (recurringIntervalPrice === RecurringIntervalPrice.YEAR) {
            const monthlyPrice = currentValue.prices.find(
              (p) => p.recurringInterval === RecurringIntervalPrice.MONTH,
            );
            if (monthlyPrice) {
              discount = monthlyPrice.amount * 12 - intervalPrice.amount;
            }
          }
          return previousValue.concat({
            ...currentValue,
            price: {
              ...intervalPrice,
              discount,
            },
          });
        }, [])
        .sort((a, b) => a.price.amount - b.price.amount),
    [data.subscriptionPlans, recurringIntervalPrice],
  );

  const handleOnRecurringIntervalPriceChanged = useCallback(
    async (event: SelectChangeEvent<RecurringIntervalPrice>) => {
      await setRecurringIntervalPrice(
        event.target.value as RecurringIntervalPrice,
      );
    },
    [setRecurringIntervalPrice],
  );

  const handleOnPlanClick = useCallback(
    async ({ id, price }: SubscriptionPlanWithPrice) => {
      const result = await checkoutSubscriptionForCompany({
        variables: {
          input: {
            subscriptionPlanId: id,
            priceAmount: price.amount,
            recurringInterval: price.recurringInterval,
            lookupKey: price.lookupKey,
            cancelUrl: window.location.origin,
            successUrl: window.location.origin,
          },
        },
      });

      if (result.data?.checkoutSubscriptionForCompany) {
        window.open(result.data.checkoutSubscriptionForCompany, '_self');
      }
    },
    [checkoutSubscriptionForCompany],
  );

  return (
    <Stack spacing={4}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Select<RecurringIntervalPrice>
          variant="outlined"
          value={recurringIntervalPrice}
          onChange={handleOnRecurringIntervalPriceChanged}>
          <MenuItem value={RecurringIntervalPrice.MONTH}>
            {t('manager:subscriptionPlan.recurringIntervalPrice.monthly')}
          </MenuItem>
          <MenuItem value={RecurringIntervalPrice.YEAR}>
            {t('manager:subscriptionPlan.recurringIntervalPrice.yearly')}
          </MenuItem>
        </Select>
      </Stack>
      <Grid2 container spacing={4} justifyContent="center">
        {filteredAndSortedSubscriptionPlans.map((subscriptionPlan) => (
          <Grid2
            key={`${subscriptionPlan.id}_${subscriptionPlan.price.id}`}
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 4,
              xl: 4,
            }}>
            <SubscriptionPlanItem<SubscriptionPlanWithPrice>
              subscriptionPlan={subscriptionPlan}
              disabled={loading}
              onPlanClick={handleOnPlanClick}
            />
          </Grid2>
        ))}
      </Grid2>
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="caption" align="center">
          {t('manager:subscriptionPlan.pricesWithoutVAT')}
        </Typography>
      </Stack>
    </Stack>
  );
}
