import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import { CampaignGiftType } from '@social-garden/utils/types';
import { CampaignRewardType } from '@social-garden/api/gql/graphql.ts';
import {
  BrandNameRule,
  CampaignAddressRule,
  CampaignDescriptionRule,
  CampaignNameRule,
  CheckboxRule,
  CodeRule,
  CompanyNameRule,
  CampaignTypeRule,
  EmailRule,
  GiftCodeRule,
  GiftDescriptionRule,
  GiftNameRule,
  ImageFileRule,
  MoneyRule,
  PasswordRule,
  QuestionRule,
  ReasonRule,
  TaskItemRule,
  TextMessageRule,
  IDRule,
  BooleanRule,
  ApplyUntilRule,
} from './Rules.ts';

z.setErrorMap(zodI18nMap);
export type EmailLoginFieldValues = z.infer<typeof EmailLoginSchema>;
export type RegisterCompanyFieldValues = z.infer<typeof RegisterCompanySchema>;
export type VerifyEmailForm = z.infer<typeof VerifyEmailSchema>;
export type ForgotPasswordFieldValues = z.infer<typeof ForgotPasswordSchema>;
export type RecoverPasswordFieldValues = z.infer<typeof RecoverPasswordSchema>;
export type UpdatePasswordFieldValues = z.infer<typeof UpdatePasswordSchema>;
export type RequestVerificationOfNewManagerEmailFieldValues = z.infer<
  typeof RequestVerificationOfNewManagerEmailSchema
>;
export type CreateCampaignFieldValues = z.infer<typeof CreateCampaignSchema>;
export type UpdateCampaignFieldValues = z.infer<typeof UpdateCampaignSchema>;
export type AcceptCampaignApplicationFieldValues = z.infer<
  typeof AcceptCampaignApplicationSchema
>;
export type RetryPreviewFieldValues = z.infer<typeof RetryPreviewSchema>;
export type CreateBrandFieldValues = z.infer<typeof CreateBrandSchema>;
export type UpdateBrandFieldValues = z.infer<typeof UpdateBrandSchema>;
export type DeleteManagerFieldValues = z.infer<typeof DeleteManagerSchema>;
export type SendMessageFieldValues = z.infer<typeof SendMessageSchema>;
export type UpdateApplyUntilFieldValues = z.infer<
  typeof UpdateApplyUntilSchema
>;

export const EmailLoginSchema = z.object({
  email: EmailRule,
  password: PasswordRule,
});

export const RegisterCompanySchema = z
  .object({
    name: CompanyNameRule,
    email: EmailRule,
    password: PasswordRule,
    passwordConfirm: PasswordRule,
    confirmedTermsOfService: CheckboxRule,
    confirmedPrivacyTerms: CheckboxRule,
    acceptedNewsletter: BooleanRule,
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ['passwordConfirm'],
  });

export const VerifyEmailSchema = z.object({
  email: EmailRule,
  code: CodeRule,
});

export const ForgotPasswordSchema = z.object({
  email: EmailRule,
});

export const RecoverPasswordSchema = z
  .object({
    email: EmailRule,
    code: CodeRule,
    password: PasswordRule,
    passwordConfirm: PasswordRule,
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ['passwordConfirm'],
  });

export const UpdatePasswordSchema = z
  .object({
    oldPassword: PasswordRule,
    newPassword: PasswordRule,
    newPasswordConfirm: PasswordRule,
  })
  .refine(
    ({ newPassword, newPasswordConfirm }) => newPassword === newPasswordConfirm,
    {
      path: ['newPasswordConfirm'],
    },
  );

export const RequestVerificationOfNewManagerEmailSchema = z.object({
  email: EmailRule,
});

const CampaignRewardSchema = z
  .object({
    rewardType: z.nativeEnum(CampaignRewardType),
    fixedMoneyRewardValue: MoneyRule.optional(),
  })
  .refine(
    ({ rewardType, fixedMoneyRewardValue }) =>
      rewardType !== CampaignRewardType.FIXED_MONEY_REWARD ||
      (typeof fixedMoneyRewardValue === 'number' &&
        fixedMoneyRewardValue >= 5000),
    {
      message: 'Min 50 €',
      path: ['fixedMoneyRewardValue'],
    },
  );

const CampaignGiftSchema = z
  .object({
    name: GiftNameRule,
    description: GiftDescriptionRule,
    type: z.nativeEnum(CampaignGiftType),
  })
  .array()
  .max(5);

const CampaignQuestionsSchema = z
  .object({
    question: QuestionRule,
  })
  .array()
  .max(5);

export const CreateCampaignSchema = z.object({
  name: CampaignNameRule,
  description: CampaignDescriptionRule,
  brandId: IDRule,
  taskItems: TaskItemRule.array().min(1).max(10),
  address: CampaignAddressRule,
  applyUntil: ApplyUntilRule,
  categoryId: IDRule,
  type: CampaignTypeRule,
  headerImage: ImageFileRule,
  reward: CampaignRewardSchema,
  gifts: CampaignGiftSchema,
  questions: CampaignQuestionsSchema,
  includeContentFileOnSubmission: BooleanRule,
});

export const UpdateCampaignSchema = z.object({
  name: CampaignNameRule,
  description: CampaignDescriptionRule,
  brandId: IDRule,
  taskItems: TaskItemRule.array().min(1).max(10),
  address: CampaignAddressRule,
  applyUntil: ApplyUntilRule,
  categoryId: IDRule,
  type: CampaignTypeRule,
  reward: CampaignRewardSchema,
  gifts: CampaignGiftSchema,
  questions: CampaignQuestionsSchema,
  includeContentFileOnSubmission: BooleanRule,
});

export const AcceptCampaignApplicationSchema = z.object({
  campaignGiftCodes: z
    .object({
      campaignGiftId: IDRule,
      code: GiftCodeRule,
    })
    .array(),
});

export const RetryPreviewSchema = z.object({
  reason: ReasonRule,
});

export const CreateBrandSchema = z.object({
  logo: ImageFileRule,
  name: BrandNameRule,
});

export const UpdateBrandSchema = z.object({
  name: BrandNameRule,
});

export const DeleteManagerSchema = z.object({
  isConfirmed: z.literal<boolean>(true),
});

export const SendMessageSchema = z.object({
  text: TextMessageRule,
});

export const UpdateApplyUntilSchema = z.object({
  applyUntil: ApplyUntilRule,
});
