import { Category, Download } from '@social-garden/utils/types.ts';
import {
  CampaignStatus,
  CampaignTypeableFragment,
} from '@social-garden/api/gql/graphql.ts';
import { Chip, Stack, Grid2 } from '@mui/material';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import { getContrastColor } from '@social-garden/utils/helpers.ts';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import { useTranslation } from 'react-i18next';
import Brand from '../../components/Brand.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import useCampaignStatus from '../../hooks/useCampaignStatus.ts';

interface CampaignReportHeaderProps {
  campaign: {
    name: string;
    status: CampaignStatus;
    brand: {
      name: string;
      logo: Download;
    };
    category: Category;
    typeable: CampaignTypeableFragment['typeable'];
  };
}

export default function CampaignReportHeader({
  campaign,
}: CampaignReportHeaderProps) {
  const { t } = useTranslation(['manager']);
  const { getCampaignStatusName } = useCampaignStatus();

  return (
    <Stack spacing={2}>
      <PageTitle>
        {t('manager:report.title', {
          campaignName: campaign.name,
        })}
      </PageTitle>
      <Brand name={campaign.brand.name} logo={campaign.brand.logo} />
      <Grid2 container spacing={1} size={12}>
        <Grid2 size="auto">
          <Chip
            sx={{
              backgroundColor: getChannelTypeColor(
                campaign.typeable.channelType,
              ),
              color: getContrastColor(
                getChannelTypeColor(campaign.typeable.channelType),
              ),
            }}
            label={getChannelTypeName(campaign.typeable.channelType)}
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            sx={{
              backgroundColor: getChannelTypeColor(
                campaign.typeable.channelType,
              ),
              color: getContrastColor(
                getChannelTypeColor(campaign.typeable.channelType),
              ),
            }}
            label={getContentTypeNameFromCampaignType(campaign.typeable)}
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip label={campaign.category.name} variant="filled" size="small" />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={getCampaignStatusName(campaign.status)}
            variant="filled"
            size="small"
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
}
