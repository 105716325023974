import { Grid2, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';
import ChannelName from './ChannelName.tsx';

export interface InstagramChannelStatisticsData {
  username: string;
  url: string;
  followersCount: number;
  mediaCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface InstagramChannelStatisticsProps {
  data: InstagramChannelStatisticsData;
}

export default function InstagramChannelStatistics({
  data,
}: InstagramChannelStatisticsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Tooltip
          title={
            <iframe
              style={{
                display: 'block',
                width: 300,
                height: 395,
                padding: 0,
                margin: 0,
                border: 0,
              }}
              title={data.username}
              src={`${data.url}/embed`}
            />
          }
          TransitionProps={{
            style: {
              padding: 0,
              maxWidth: 'none',
              overflow: 'hidden',
            },
          }}>
          <span>
            <ChannelName name={data.username} url={data.url} />
          </span>
        </Tooltip>
      </Grid2>
      <Grid2 container size={12} spacing={2}>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.followerCount', {
              count: data.followersCount,
            })}
            value={abbreviateCount(data.followersCount, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(
              data.averageEngagementRate || 0,
              resolvedLanguage,
            )}
            description={t('common:channel.averageEngagementRateDescription')}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={abbreviateCount(data.averageReach || 0, resolvedLanguage)}
            description={t('common:channel.averageReachDescription')}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.mediaCount', {
              count: data.mediaCount,
            })}
            value={abbreviateCount(data.mediaCount, resolvedLanguage)}
            description={t('common:channel.mediaCountDescription')}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
