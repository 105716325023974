import { Button, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { createBrandDocument } from '@social-garden/api/documents/brand.ts';
import { useNavigate } from 'react-router-dom';
import { managerLayoutDocument } from '@social-garden/api/documents/manager.ts';
import ImageCropField from '../../components/ImageCropField.tsx';
import {
  CreateBrandFieldValues,
  CreateBrandSchema,
} from '../../constants/ValidationSchema.ts';
import { FormFieldProps } from '../../utils/types.ts';
import BrandNameField from '../../components/BrandNameField.tsx';

export default function CreateFirstBrand() {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'manager']);
  const [createBrand, { loading }] = useMutation(createBrandDocument, {
    refetchQueries: [managerLayoutDocument],
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateBrandFieldValues>({
    mode: 'all',
    resolver: zodResolver(CreateBrandSchema),
  });

  const renderLogoField = useCallback(
    ({
      field: { name, onChange },
      fieldState: { error, invalid },
    }: FormFieldProps<CreateBrandFieldValues, 'logo'>) => (
      <ImageCropField
        fullWidth
        label={t('common:brand.logo')}
        width={500}
        height={500}
        error={invalid}
        helperText={error?.message}
        name={name}
        cropShape="round"
        onChange={onChange}
      />
    ),
    [t],
  );

  const handleOnValid = useCallback(
    async ({ logo, name }: CreateBrandFieldValues) => {
      const { data } = await createBrand({
        variables: {
          input: {
            logo,
            name,
          },
        },
      });
      if (data?.createBrand) {
        navigate('/');
      }
    },
    [createBrand, navigate],
  );

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 6,
      }}
      maxWidth="md"
      component="form"
      noValidate
      onSubmit={handleSubmit(handleOnValid)}>
      <Typography variant="h2" align="center">
        {t('manager:noBrand.title')}
      </Typography>
      <Typography variant="h5" align="center">
        {t('manager:noBrand.message')}
      </Typography>
      <Stack spacing={2}>
        <Controller<CreateBrandFieldValues, 'logo'>
          control={control}
          render={renderLogoField}
          name="logo"
        />
        <Controller<CreateBrandFieldValues, 'name'>
          control={control}
          render={BrandNameField}
          name="name"
        />
        <Button
          type="submit"
          variant="contained"
          disabled={!isValid || loading}>
          {t('manager:brand.create.submitButton')}
        </Button>
      </Stack>
    </Container>
  );
}
