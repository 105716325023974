import { useMemo } from 'react';
import { getLatestPreviewRevision } from '@social-garden/utils/helpers.ts';
import {
  getContentTypeNameFromCampaignType,
  getMediaTypeFromContentType,
} from '@social-garden/utils/content.ts';
import { CampaignTypeableFragment } from '@social-garden/api/gql/graphql.ts';
import PreviewRevision from '../../components/PreviewRevision.tsx';

export interface CreatorHasCampaignPreviewProps {
  creatorHasCampaign: {
    previewRevisions: {
      id: string;
      caption: string;
    }[];
    campaign: {
      typeable: CampaignTypeableFragment['typeable'];
    };
  };
}

export default function CreatorHasCampaignPreview({
  creatorHasCampaign,
}: CreatorHasCampaignPreviewProps) {
  const latestPreviewRevision = useMemo(
    () => getLatestPreviewRevision(creatorHasCampaign.previewRevisions),
    [creatorHasCampaign.previewRevisions],
  );

  const mediaType = useMemo(
    () =>
      getMediaTypeFromContentType(
        getContentTypeNameFromCampaignType(
          creatorHasCampaign.campaign.typeable,
        ),
      ),
    [creatorHasCampaign.campaign.typeable],
  );

  if (latestPreviewRevision === undefined) {
    return null;
  }

  return (
    <PreviewRevision
      mediaStyle={{
        maxHeight: 600,
      }}
      id={latestPreviewRevision.id}
      caption={latestPreviewRevision.caption}
      mediaType={mediaType}
    />
  );
}
