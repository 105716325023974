import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid2,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@apollo/client';
import { campaignsDocument } from '@social-garden/api/documents/campaign.ts';
import { Link } from 'react-router-dom';
import { CampaignStatus } from '@social-garden/api/gql/graphql.ts';
import BlogPostItem from '../../components/BlogPostItem.tsx';
import CampaignListItem from '../../components/CampaignListItem.tsx';
import PageTitle from '../../components/PageTitle.tsx';

export default function Dashboard() {
  const { t } = useTranslation(['manager']);
  const { data } = useSuspenseQuery(campaignsDocument, {
    variables: {
      input: {
        brandIds: [],
        status: [
          CampaignStatus.DRAFT,
          CampaignStatus.ACTIVE,
          CampaignStatus.COMPLETED,
        ],
      },
      first: 6,
      page: 1,
    },
  });

  if (data.campaigns.data.length === 0) {
    return (
      <Container>
        <Grid2 container spacing={6}>
          <Grid2 size={12}>
            <Stack spacing={2}>
              <PageTitle align="center">
                {t('dashboard.title', {
                  appName: import.meta.env.VITE_APP_NAME,
                })}
              </PageTitle>
              <Typography variant="h5" align="center">
                {t('dashboard.subtitle')}
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 size={12}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  minHeight: 300,
                }}>
                <Typography
                  variant="h5"
                  align="center"
                  fontWeight={300}
                  lineHeight={1.8}
                  color="text.secondary">
                  {t('dashboard.createCampaignHeader')}
                </Typography>
                <Button
                  component={Link}
                  to="/create-campaign"
                  variant="contained"
                  size="large">
                  {t('dashboard.createCampaignButton')}
                </Button>
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={12}>
            <Divider variant="middle">
              <Chip label={t('dashboard.howToDivider')} variant="outlined" />
            </Divider>
          </Grid2>
          <Grid2 container size={12} spacing={4}>
            <Grid2
              size={{
                xs: 12,
                md: 4,
              }}>
              <BlogPostItem
                href="https://social.garden/blog/authentischer-und-effektiver-content"
                image="https://social.garden/wp-content/uploads/2023/09/Rectangle-13.png"
                title="Lokale Influencer: Authentischer und effektiver Content"
                content="Influencer Marketing ist längst keine Neuheit mehr, aber es bleibt eine äußerst wirksame Methode, um deine Botschaft an ein engagiertes Publikum zu verbreiten. Die Wahl lokaler Influencer kann jedoch eine noch effektivere Herangehensweise sein."
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 4,
              }}>
              <BlogPostItem
                href="https://social.garden/blog/influencer-verguetungen"
                image="https://social.garden/wp-content/uploads/2023/09/carl-heyerdahl-KE0nC8-58MQ-unsplash.jpg"
                title="Influencer Vergütungen – Produktmuster, Honorare und Kommissionen"
                content="In der dynamischen Welt des Influencer-Marketings sind Influencer Vergütungen ein entscheidender Faktor für den Erfolg deiner Kampagnen. Die richtige Vergütungsstrategie kann den Unterschied zwischen einer erfolgreichen Zusammenarbeit und einem verpassten Potenzial ausmachen."
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 4,
              }}>
              <BlogPostItem
                href="https://social.garden/blog/social-garden-effektive-kampagnen-briefings"
                image="https://social.garden/wp-content/uploads/2023/08/melanie-deziel-U33fHryBYBU-unsplash.jpg"
                title="Influencer Marketing: Effektive Kampagnen Briefings in 5 Schritten"
                content="Ein gut strukturiertes Kampagnen Briefing ist das Rückgrat jeder erfolgreichen Influencer-Marketing-Kampagne. Es dient als Kommunikationsmittel zwischen dir als Marke und den Influencern, die deine Botschaft verbreiten werden."
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    );
  }

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 container alignItems="center" size={12}>
          <Grid2
            size={{
              xs: 12,
              md: 'grow',
            }}>
            <PageTitle>{t('dashboard.latestCampaignsHeader')}</PageTitle>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 'auto',
            }}>
            <Button
              component={Link}
              to="/create-campaign"
              size="large"
              variant="contained">
              {t('dashboard.createCampaignButton')}
            </Button>
          </Grid2>
        </Grid2>
        {data.campaigns.data.map((campaign) => (
          <Grid2
            key={campaign.id}
            size={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 4,
            }}>
            <CampaignListItem data={campaign} variant="outlined" />
          </Grid2>
        ))}
        <Grid2 textAlign="center" size={12}>
          <Button variant="text" component={Link} to="/campaigns">
            {t('dashboard.allCampaignsButton')}
          </Button>
        </Grid2>
      </Grid2>
    </Container>
  );
}
