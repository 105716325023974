import { Button, Container, Stack, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { settingsDocument } from '@social-garden/api/documents/manager.ts';
import Section from '../../components/Section.tsx';
import useCampaignUsage from '../../hooks/useCampaignUsage.ts';
import useBrandUsage from '../../hooks/useBrandUsage.ts';
import SubscriptionPlanItem from '../../components/SubscriptionPlanItem.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import useStorageSpaceUsage from '../../hooks/useStorageSpaceUsage.ts';
import CampaignUsageProgress from '../../components/CampaignUsageProgress.tsx';
import BrandUsageProgress from '../../components/BrandUsageProgress.tsx';
import StorageSpaceUsageProgress from '../../components/StorageSpaceUsageProgress.tsx';

export default function Subscription() {
  const { t } = useTranslation(['common', 'manager']);
  const { data } = useSuspenseQuery(settingsDocument);
  const { pathname } = useLocation();

  const {
    max: maxCampaignUsage,
    current: currentCampaignUsage,
    exceeded: campaignUsageExceeded,
  } = useCampaignUsage();
  const {
    max: maxBrandUsage,
    current: currentBrandUsage,
    exceeded: brandUsageExceeded,
  } = useBrandUsage();
  const {
    max: maxStorageSpaceUsage,
    current: currentStorageSpaceUsage,
    exceeded: storageSpaceUsageExceeded,
  } = useStorageSpaceUsage();

  const returnUrl = useMemo(
    () => window.location.origin + pathname,
    [pathname],
  );

  if (data.whoAmI.__typename !== 'Manager') {
    throw new Error('Authenticated user is not a manager!');
  }

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 size={12}>
          <PageTitle>{t('manager:subscription.title')}</PageTitle>
        </Grid2>
        {data.whoAmI.company.currentSubscription?.subscriptionPlan ? (
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}>
            <SubscriptionPlanItem
              sx={{
                borderColor: (theme) => theme.palette.primary.main,
              }}
              subscriptionPlan={
                data.whoAmI.company.currentSubscription.subscriptionPlan
              }
              disablePriceHighlighting
            />
          </Grid2>
        ) : null}
        <Grid2
          size={{
            xs: 12,
            md: 'grow',
          }}>
          <Stack spacing={4}>
            <Section header={t('manager:subscription.header.usage')}>
              <CampaignUsageProgress
                current={currentCampaignUsage}
                max={maxCampaignUsage}
              />
              <BrandUsageProgress
                current={currentBrandUsage}
                max={maxBrandUsage}
              />
              <StorageSpaceUsageProgress
                current={currentStorageSpaceUsage}
                max={maxStorageSpaceUsage}
              />
              <Button
                fullWidth
                variant="contained"
                size={
                  campaignUsageExceeded ||
                  brandUsageExceeded ||
                  storageSpaceUsageExceeded
                    ? 'large'
                    : 'medium'
                }
                href={`mailto:${import.meta.env.VITE_SALES_EMAIL}`}>
                {t('common:contactSales')}
              </Button>
            </Section>
            <Button
              fullWidth
              variant="outlined"
              component={Link}
              to={`/customer-portal?return-url=${returnUrl}`}>
              {t('manager:manageStripeAccountButton')}
            </Button>
          </Stack>
        </Grid2>
      </Grid2>
    </Container>
  );
}
