import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { assertUnreachable } from '@social-garden/utils/helpers';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';

export default function useCreatorHasCampaignStatus() {
  const { t } = useTranslation(['common']);

  const getCreatorHasCampaignStatusName = useCallback(
    (status: CreatorHasCampaignStatus) => {
      switch (status) {
        case CreatorHasCampaignStatus.APPLIED:
          return t('common:creatorHasCampaignStatus.applied');
        case CreatorHasCampaignStatus.APPLICATION_DECLINED:
          return t('common:creatorHasCampaignStatus.applicationDeclined');
        case CreatorHasCampaignStatus.PREVIEW_PENDING:
          return t('common:creatorHasCampaignStatus.previewPending');
        case CreatorHasCampaignStatus.PREVIEW_SUBMITTED:
          return t('common:creatorHasCampaignStatus.previewSubmitted');
        case CreatorHasCampaignStatus.CONTENT_PENDING:
          return t('common:creatorHasCampaignStatus.contentPending');
        case CreatorHasCampaignStatus.CONTENT_SUBMITTED:
          return t('common:creatorHasCampaignStatus.contentSubmitted');
        case CreatorHasCampaignStatus.REPORT_FAILED:
          return t('common:creatorHasCampaignStatus.reportFailed');
        case CreatorHasCampaignStatus.REPORT_COMPLETED:
          return t('common:creatorHasCampaignStatus.reportCompleted');
        case CreatorHasCampaignStatus.DISCONTINUED:
          return t('common:creatorHasCampaignStatus.discontinued');
        case CreatorHasCampaignStatus.CAMPAIGN_SUSPENDED:
          return t('common:creatorHasCampaignStatus.campaignSuspended');
        default:
          assertUnreachable(status);
      }
    },
    [t],
  );

  const getCreatorHasCampaignStatusColor = useCallback(
    (status: CreatorHasCampaignStatus) => {
      switch (status) {
        case CreatorHasCampaignStatus.APPLIED:
          return 'applied';
        case CreatorHasCampaignStatus.APPLICATION_DECLINED:
          return 'applicationDeclined';
        case CreatorHasCampaignStatus.PREVIEW_PENDING:
          return 'previewPending';
        case CreatorHasCampaignStatus.PREVIEW_SUBMITTED:
          return 'previewSubmitted';
        case CreatorHasCampaignStatus.CONTENT_PENDING:
          return 'contentPending';
        case CreatorHasCampaignStatus.CONTENT_SUBMITTED:
          return 'contentSubmitted';
        case CreatorHasCampaignStatus.REPORT_FAILED:
          return 'reportFailed';
        case CreatorHasCampaignStatus.REPORT_COMPLETED:
          return 'reportCompleted';
        case CreatorHasCampaignStatus.DISCONTINUED:
          return 'discontinued';
        case CreatorHasCampaignStatus.CAMPAIGN_SUSPENDED:
          return 'campaignSuspended';
        default:
          assertUnreachable(status);
      }
    },
    [],
  );

  return {
    getCreatorHasCampaignStatusName,
    getCreatorHasCampaignStatusColor,
  };
}
