import {
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Grid2,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import { getChannelTypeName } from '@social-garden/utils/channel.ts';
import {
  assertUnreachable,
  formatEuroCents,
  getGoogleMapsSearchUrl,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import { useSuspenseQuery } from '@apollo/client';
import { campaignBriefingDocument } from '@social-garden/api/documents/campaign.ts';
import { CampaignRewardType } from '@social-garden/api/gql/graphql.ts';
import Brand from '../../components/Brand.tsx';
import ReplaceUrlWithLink from '../../components/ReplaceUrlWithLink.tsx';
import useCampaignRewardType from '../../hooks/useCampaignRewardType.ts';

interface CampaignBriefingProps extends CardProps {
  campaignId: string;
}

export default function CampaignBriefing({
  campaignId,
  children,
  ...rest
}: CampaignBriefingProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);
  const {
    data: { campaign },
  } = useSuspenseQuery(campaignBriefingDocument, {
    variables: {
      id: campaignId,
    },
  });

  const { getCampaignRewardTypeName } = useCampaignRewardType();

  return (
    <Card {...rest}>
      <CardMedia
        sx={{ aspectRatio: 21 / 9 }}
        image={getUriWithHash(campaign.headerImage)}
        title={campaign.name}
        draggable={false}
      />
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 container spacing={1} alignItems="center" size={12}>
            <Grid2 size={12}>
              <Typography fontSize={24}>{campaign.name}</Typography>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 'grow',
              }}>
              <Brand
                name={campaign.brand.name}
                logo={campaign.brand.logo}
                size="small"
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 'auto',
              }}>
              <Chip
                label={campaign.category.name}
                variant="filled"
                size="small"
              />
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}>
              <ReplaceUrlWithLink>{campaign.description}</ReplaceUrlWithLink>
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 6,
            }}>
            <Typography fontWeight={500}>
              {t('common:campaign.channelType')}
            </Typography>
            <Typography>
              {getChannelTypeName(campaign.typeable.channelType)}
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 6,
            }}>
            <Typography fontWeight={500}>
              {t('common:campaign.contentType')}
            </Typography>
            <Typography>
              {getContentTypeNameFromCampaignType(campaign.typeable)}
            </Typography>
          </Grid2>
          {campaign.applyUntil ? (
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}>
              <Typography fontWeight={500}>
                {t('common:campaign.applyUntil')}
              </Typography>
              <Typography>
                {new Date(campaign.applyUntil).toLocaleString(
                  resolvedLanguage,
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  },
                )}
              </Typography>
            </Grid2>
          ) : null}
          <Grid2
            size={{
              xs: 12,

              sm:
                campaign.rewardType === CampaignRewardType.FIXED_MONEY_REWARD &&
                campaign.fixedMoneyRewardValue
                  ? 6
                  : 12,
            }}>
            <Typography fontWeight={500}>
              {t('common:campaign.rewardType')}
            </Typography>
            <Typography>
              {getCampaignRewardTypeName(campaign.rewardType)}
            </Typography>
          </Grid2>
          {campaign.rewardType === CampaignRewardType.FIXED_MONEY_REWARD &&
          campaign.fixedMoneyRewardValue ? (
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}>
              <Typography fontWeight={500}>
                {t('common:campaignRewardType.fixedMoneyReward')}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <Typography>
                  {formatEuroCents(
                    campaign.fixedMoneyRewardValue,
                    resolvedLanguage,
                  )}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {t('common:withoutVAT')}
                </Typography>
              </Stack>
            </Grid2>
          ) : null}
          <Grid2 size={12}>
            <Typography fontWeight={500}>
              {t('common:campaign.includeContentFileOnSubmission')}
            </Typography>
            <Typography>
              {campaign.includeContentFileOnSubmission
                ? t('common:yes')
                : t('common:no')}
            </Typography>
          </Grid2>
          {campaign.address ? (
            <Grid2 size={12}>
              <Typography fontWeight={500}>
                {t('common:campaign.address')}
              </Typography>
              <Link
                href={getGoogleMapsSearchUrl(campaign.address)}
                target="_blank">
                {campaign.address}
              </Link>
            </Grid2>
          ) : null}
          <Grid2 size={12}>
            <Typography fontWeight={500}>
              {t('common:campaign.taskItem', {
                count: campaign.taskItems.length,
              })}
            </Typography>
            <List disablePadding>
              {campaign.taskItems.map(({ taskable }) => {
                switch (taskable.__typename) {
                  case 'FreeTextTask':
                    return (
                      <ListItem key={taskable.id} disableGutters disablePadding>
                        <ListItemText primary={taskable.description} />
                      </ListItem>
                    );
                  case 'HashtagTask':
                    return (
                      <ListItem key={taskable.id} disableGutters disablePadding>
                        <ListItemText
                          primary={t('common:taskItemText.hashtagTask', {
                            count: taskable.hashtags.length,
                          })}
                          secondary={taskable.hashtags.join(' ')}
                          secondaryTypographyProps={{
                            color: 'primary',
                          }}
                        />
                      </ListItem>
                    );
                  case 'WebsiteTask':
                    return (
                      <ListItem key={taskable.id} disableGutters disablePadding>
                        <ListItemText
                          primary={t('common:taskItemText.websiteTask')}
                          secondary={
                            <Link href={taskable.url} target="_blank">
                              {taskable.url}
                            </Link>
                          }
                        />
                      </ListItem>
                    );
                  default:
                    assertUnreachable(taskable);
                }
              })}
            </List>
          </Grid2>
          {campaign.gifts.length > 0 ? (
            <Grid2 size={12}>
              <Typography fontWeight={500}>
                {t('manager:campaign.form.section.gift.header')}
              </Typography>
              <Stack spacing={1}>
                {campaign.gifts.map((gift) => (
                  <Stack key={gift.id}>
                    <Typography variant="subtitle2">{gift.name}</Typography>
                    <Typography variant="body2">{gift.description}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid2>
          ) : null}
          {campaign.questions.length > 0 ? (
            <Grid2 size={12}>
              <Typography fontWeight={500}>
                {t('common:campaign.question', {
                  count: campaign.questions.length,
                })}
              </Typography>
              <Stack spacing={1}>
                {campaign.questions.map((question, index) => (
                  <Typography key={question.id}>
                    {`${index + 1}. ${question.question}`}
                  </Typography>
                ))}
              </Stack>
            </Grid2>
          ) : null}
          {children}
        </Grid2>
      </CardContent>
    </Card>
  );
}
