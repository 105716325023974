import { Alert, Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { creatorHasCampaignContentFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';

interface CreatorHasCampaignContentFileProps {
  creatorHasCampaign: {
    id: string;
    status: CreatorHasCampaignStatus;
    campaign: {
      includeContentFileOnSubmission: boolean;
    };
  };
}

export default function CreatorHasCampaignContentFile({
  creatorHasCampaign,
}: CreatorHasCampaignContentFileProps) {
  const { t } = useTranslation(['manager']);

  const [creatorHasCampaignContentFile, { loading }] = useLazyQuery(
    creatorHasCampaignContentFileDocument,
  );

  const canDownloadContentFile = useMemo(
    () =>
      creatorHasCampaign.campaign.includeContentFileOnSubmission &&
      (creatorHasCampaign.status ===
        CreatorHasCampaignStatus.CONTENT_SUBMITTED ||
        creatorHasCampaign.status === CreatorHasCampaignStatus.REPORT_FAILED ||
        creatorHasCampaign.status ===
          CreatorHasCampaignStatus.REPORT_COMPLETED),
    [
      creatorHasCampaign.campaign.includeContentFileOnSubmission,
      creatorHasCampaign.status,
    ],
  );

  const handleOnDownloadContentFile = useCallback(async () => {
    if (!canDownloadContentFile) {
      return;
    }
    const response = await creatorHasCampaignContentFile({
      variables: {
        id: creatorHasCampaign.id,
      },
    });
    if (response.data) {
      window.open(response.data.creatorHasCampaignContentFile.uri, '_blank');
    }
  }, [
    canDownloadContentFile,
    creatorHasCampaign.id,
    creatorHasCampaignContentFile,
  ]);

  if (!canDownloadContentFile) {
    return null;
  }

  return (
    <Alert
      severity="info"
      action={
        <Button
          color="inherit"
          size="small"
          disabled={loading}
          onClick={handleOnDownloadContentFile}>
          {t('manager:creatorHasCampaign.contentFile.downloadButton')}
        </Button>
      }>
      {t('manager:creatorHasCampaign.contentFile.infoText')}
    </Alert>
  );
}
